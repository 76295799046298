// // Table.js
// import React from "react";
// import "./table.css";
// const Table = ({filteredAdmins}) => {
// console.log("filteredAdmins ",filteredAdmins )
// const color = getColorBasedOnPercentage(percentage);
// function getColorBasedOnPercentage(percentage) {
// 	// Define the color range from red to green
// 	const red = Math.max(255 - (percentage * 2.55), 0); // Red decreases as percentage increases
// 	const green = Math.min(percentage * 2.55, 255); // Green increases as percentage increases
	
// 	return `rgb(${red}, ${green}, 0)`; // Returns color in RGB format
//   }

// 	return (
// 		<div className=" ">
// 			<div
// 				style={{
// 					padding: "20px",
// 					backgroundColor: "#202125",
// 					borderRadius: "8px",
// 				}}
// 				className=" givewidht lg:overflow-x-hidden md:overflow-x-scroll w-full">
// 				<table
// 					style={{ borderCollapse: "collapse" }}
// 					className="mywidht lg:w-[100%] md:w-[150%]  w-[200%]">
// 					<thead>
// 						<tr>
// 							<th
// 								style={{
// 									paddingInline: "10px",
// 									paddingBlock : "15px",
// 									color: "#fff",
// 								}}>
// 								#
// 							</th>
// 							<th
// 								style={{
// 									paddingInline: "10px",
// 									paddingBlock : "15px",
// 									textAlign: "left",
// 									color: "#fff",
// 								}}>
// 								Name 
// 							</th>
// 							<th
// 								style={{
// 									textAlign: "left",
// 									paddingInline: "10px",
// 									paddingBlock : "15px",
// 									color: "#fff",
// 								}}>
// 								Popularity
// 							</th>
// 							<th
// 								style={{
// 									paddingInline: "10px",
// 									paddingBlock : "15px",
// 									color: "#fff",
// 								}}>
// 								Sales
// 							</th>
// 						</tr>
// 					</thead>
// 					<tbody>
// 						{filteredAdmins.map(({ _id, name, percentage , sales } , index) => (
// 							<tr
// 								key={_id}
// 								style={{ backgroundColor: "inherit", color: "#ffff" }}>
// 								<td
// 									style={{
// 										paddingInline: "5px",
// 										paddingBlock: "8px",
// 										borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
// 									}}>
// 									{index+1}
// 								</td>
// 								<td
// 									style={{
// 										paddingInline: "5px",
// 										paddingBlock: "8px",
// 										borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
// 									}}>
// 									{name
// 									}
// 								</td>
// 								<td
// 									style={{
// 										paddingInline: "5px",
// 										paddingBlock: "8px",
// 										borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
// 									}}>
// 									<div
// 										style={{
// 											height: "6px",
// 											width: "100%",
// 											backgroundColor: "#e0e0e0",
// 											borderRadius: "10px",
// 											overflow: "hidden",
// 										}}>
// 										<div
// 											style={{
// 												height: "100%",
// 												width: `${percentage}%`,
// 												backgroundColor: "blue", // Use the color from the data
// 												transition: "width 0.3s ease-in-out",
// 											}}
// 										/>
// 									</div>
// 								</td>
// 								<td
// 									style={{
// 										paddingInline: "5px",
// 										paddingBlock: "8px",
// 										borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
// 									}}>
// 									<div
// 										style={{
// 											paddingInline: "5px",
// 											paddingBlock: "8px",
// 											backgroundColor: `rgba(${parseInt(
// 												"red".slice(1, 3),
// 												16
// 											)}, ${parseInt("red".slice(3, 5), 16)}, ${parseInt(
// 												"red".slice(5, 7),
// 												16
// 											)}, 0.5)`, // 50% opacity
// 											border: `0.5px solid ${"red"}`,
// 											borderRadius: "5px",
// 											textAlign: "center",
// 											width: "60%",
// 											margin: "0 auto",
// 											color: "#fff", // Optional: change text color to white for better visibility
// 										}}>
// 										{percentage}%
// 									</div>
// 								</td>
// 							</tr>
// 						))}
// 					</tbody>
// 				</table>
// 			</div>
// 		</div>
// 	);
// };

// export default Table;

import React from "react";
import "./table.css";

const Table = ({ filteredAdmins }) => {
  const getColorBasedOnPercentage = (percentage) => {
    // Define the color range from red to green
    const red = Math.max(255 - (percentage * 2.55), 0); // Red decreases as percentage increases
    const green = Math.min(percentage * 2.55, 255); // Green increases as percentage increases
    return `rgb(${red}, ${green}, 0)`; // Returns color in RGB format
  };

  return (
    <div className="">
      <div
        style={{
          padding: "20px",
          backgroundColor: "#202125",
          borderRadius: "8px",
        }}
        className="givewidht lg:overflow-x-hidden md:overflow-x-scroll w-full"
      >
        <table
          style={{ borderCollapse: "collapse" }}
          className="mywidht lg:w-[100%] md:w-[150%]  w-[200%]"
        >
          <thead>
            <tr>
              <th
                style={{
                  paddingInline: "10px",
                  paddingBlock: "15px",
                  color: "#fff",
                }}
              >
                #
              </th>
              <th
                style={{
                  paddingInline: "10px",
                  paddingBlock: "15px",
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                Name
              </th>
              <th
                style={{
                  textAlign: "left",
                  paddingInline: "10px",
                  paddingBlock: "15px",
                  color: "#fff",
                }}
              >
                Popularity
              </th>
              <th
                style={{
                  paddingInline: "10px",
                  paddingBlock: "15px",
                  color: "#fff",
                }}
              >
                Sales
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredAdmins.map(({ _id, name, percentage, sales }, index) => {
              const color = getColorBasedOnPercentage(percentage); // Get color for each row based on percentage
              return (
                <tr
                  key={_id}
                  style={{ backgroundColor: "inherit", color: "#ffff" }}
                >
                  <td
                    style={{
                      paddingInline: "5px",
                      paddingBlock: "8px",
                      borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      paddingInline: "5px",
                      paddingBlock: "8px",
                      borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
                    }}
                  >
                    {name}
                  </td>
                  <td
                    style={{
                      paddingInline: "5px",
                      paddingBlock: "8px",
                      borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        height: "6px",
                        width: "100%",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: `${percentage}%`,
                          backgroundColor: color, // Use the dynamic color from percentage
                          transition: "width 0.3s ease-in-out",
                        }}
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      paddingInline: "5px",
                      paddingBlock: "8px",
                      borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        paddingInline: "5px",
                        paddingBlock: "8px",
                        backgroundColor: `rgba(${parseInt(
                          "red".slice(1, 3),
                          16
                        )}, ${parseInt("red".slice(3, 5), 16)}, ${parseInt(
                          "red".slice(5, 7),
                          16
                        )}, 0.5)`, // Red color with 50% opacity as fallback
                        border: `0.5px solid ${color}`, // Border color based on percentage
                        borderRadius: "5px",
                        textAlign: "center",
                        width: "60%",
                        margin: "0 auto",
                        color: "#fff", // Optional: white text for contrast
                      }}
                    >
                      {percentage}%
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
