import React from "react";
import { Icon } from "@iconify/react";

// Import other styles if needed
import "./table.css";
const Table = ({goals}) => {
	console.log('golas' , goals)
	const getColorBasedOnPercentage = (percentage) => {
		// Define the color range from red to green
		const red = Math.max(255 - (percentage * 2.55), 0); // Red decreases as percentage increases
		const green = Math.min(percentage * 2.55, 255); // Green increases as percentage increases
		return `rgb(${red}, ${green}, 0)`; // Returns color in RGB format
	  };

	return (
		<div className=" bg-[#202125]">
			<div
				style={{
					padding: "20px",
					backgroundColor: "#202125",
					borderRadius: "8px",
				}}
				className="givewidht lg:overflow-x-hidden md:overflow-x-scroll w-full  ">
				<div className="">
					<h2 className="text-2xl font-bold mt-2 mb-2  ">Projects Goals</h2>
					<p>
						{" "}
						<span className="w-5 h-5 rounded-full bg-[#FCB859]"> </span>30 done
						this month
					</p>
				</div>
				<table
					style={{ borderCollapse: "collapse" }}
					className="mywidht lg:w-[100%] md:w-[150%] w-[200%] font-inter">
					<thead>
						<tr>
							<th
								style={{
									fontSize: "14px",
									fontWeight: "300",
									opacity: "50%",
									paddingBlock: "30px",
									paddingInline: "5px",
									color: "#fff",
									textAlign: "start",
								}}>
								Company Name
							</th>
							<th
								style={{
									fontSize: "14px",
									fontWeight: "300",
									opacity: "50%",
									paddingBlock: "30px",
									paddingInline: "5px",
									color: "#fff",
									textAlign: "start",
								}}>
								Members
							</th>
							<th
								style={{
									fontSize: "14px",
									fontWeight: "300",
									opacity: "50%",
									paddingBlock: "30px",
									paddingInline: "5px",
									color: "#fff",
									textAlign: "start",
								}}>
								Budget
							</th>
							<th
								style={{
									fontSize: "14px",
									fontWeight: "300",
									opacity: "50%",
									paddingBlock: "30px",
									paddingInline: "5px",
									color: "#fff",
									textAlign: "start",
								}}>
								Completion
							</th>
						</tr>
					</thead>
					<tbody>
					{Array.isArray(goals) && goals.map(({ _id, companyName, memberImage = [], budget, percentage = 0, color = "#FCB859", icon = "logos:slack-icon" }) => (
						<tr
							key={_id}
							style={{ backgroundColor: "inherit", color: "#ffff" }}>
							<td
								style={{
									padding: "5px",
									paddingBlock: "15px",
									borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
								}}>
								<div className="flex gap-3">
									<Icon icon={icon} className="w-8 h-8" /> {/* Use the icon here */}
									<div>{companyName}</div>
								</div>
							</td>
							<td
								style={{
									padding: "5px",
									paddingBlock: "15px",
									borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
								}}>
								<div className="flex gap-1">
								{Array.isArray(memberImage) && memberImage.map((member, index) => (
									<img
										key={index}
										src={`https://inasnapmarketing.ai/api${member}`}
										className="w-8 h-8 rounded-full ms-[-20px] object-cover object-top"
										alt={`Member ${index + 1}`}
									/>
								))}

								</div>
							</td>
							<td
								style={{
									padding: "5px",
									paddingBlock: "15px",
									borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
								}}>
								{budget}
							</td>
							<td
								style={{
									padding: "5px",
									paddingBlock: "15px",
									borderBottom: "1px solid rgba(204, 204, 204, 0.1)",
								}}>
								<div
									style={{
										height: "6px",
										width: "100%",
										backgroundColor: "#e0e0e0",
										borderRadius: "10px",
										overflow: "hidden",
									}}
									>
									<div
										style={{
										height: "100%",
										width: `${percentage}%`, // Ensure percentage is being used correctly for the width
										backgroundColor: getColorBasedOnPercentage(percentage), // Dynamic color based on the percentage
										transition: "width 0.3s ease-in-out",
										}}
									/>
									</div>
							</td>
						</tr>
					))}

					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Table;