import React, { useEffect, useRef, useState, useCallback } from "react";
import WaveSurfer from 'https://cdn.jsdelivr.net/npm/wavesurfer.js@7/dist/wavesurfer.esm.js';
import sound1 from "../../Assets/Images/rightsound.png"; // Import the background image
import boxup from "../../Assets/Images/boxup.png"; // Import the second image
import record2 from "../../Assets/Images/recordinimg.png";

function Liftside({ data }) {
    const recordings = Array.isArray(data) ? data : [];
    const [isPlaying, setIsPlaying] = useState({});
    const wavesurferRefs = useRef([]);

    // Initialize WaveSurfer for each recording
    const initializeWaveSurfer = useCallback((index, audioUrl) => {
        wavesurferRefs.current[index] = WaveSurfer.create({
            container: `#waveform-${index}`,  // Unique ID for each recording's waveform container
            waveColor: 'rgb(255, 255, 255)',  // Wave color
            progressColor: 'rgba(255, 255, 255, 0.5)',  // Progress color
            cursorColor: 'rgba(0, 0, 0, 0.0)',  // Cursor color
            barWidth: 2,             // Bar width
            barHeight: 25,          // Height of each bar
            barGap: 3,              // Gap between bars
            barRadius: 2,           // Rounded corners for bars
            height: 60,             // Total height of the waveform container
            url: audioUrl,
        });

        // Optionally, listen for interaction to start playback
        wavesurferRefs.current[index].on('interaction', () => {
            handlePlayPause(index);
        });
    }, []);

    // Play/Pause handler for each recording
    const handlePlayPause = (index) => {
        setIsPlaying((prev) => {
            const newState = { ...prev };
            newState[index] = !newState[index];

            if (newState[index]) {
                wavesurferRefs.current[index].play();
            } else {
                wavesurferRefs.current[index].pause();
            }
            return newState;
        });
    };

    // Initialize each WaveSurfer instance after component mounts
    useEffect(() => {
        recordings.forEach((recording, index) => {
            if (recording.recordingUrl) {
                // Dynamically create the full URL using the recordingUrl
                const audioUrl = `http://localhost:3002${recording.recordingUrl}`;
                initializeWaveSurfer(index, audioUrl);
            }
        });

        // Clean up WaveSurfer instances on component unmount
        return () => {
            wavesurferRefs.current.forEach((waveSurfer) => waveSurfer.destroy());
        };
    }, [recordings, initializeWaveSurfer]);

    // Download handler
    const handleDownload = (index) => {
        const recording = recordings[index];
        if (recording && recording.recordingUrl) {
            const audioUrl = `http://localhost:3002${recording.recordingUrl}`;
            const link = document.createElement('a');
            link.href = audioUrl;
            link.download = recording.name || "audio"; // You can give a custom name to the file
            document.body.appendChild(link); // Append the link to the DOM
            link.click(); // Trigger the download
            document.body.removeChild(link); // Remove the link after clicking
        }
    };

    return (
        <div className="flex flex-col gap-10">
            {recordings.length > 0 ? (
                recordings.map((recording, index) => (
                    <div key={recording.id} className="md:px-0 md:rounded rounded-[50px] overflow-hidden">
                        <div className="w-full relative z-10">
                            <img
                                className="w-full h-full absolute left-0 top-0 -z-[9]"
                                alt={""}
                                src={record2}
                            />
                            <div className="w-full flex items-center gap-4 px-4 py-5 flex-wrap">
                                <div className=" overflow-hidden 2xl:w-36 2xl:h-36 xl:w-28 xl:h-28 sm:w-36 sm:h-36 w-24 h-24 rounded-full mx-auto bg-red-400">
                                    <img
                                        src={`http://localhost:3002${recording.imageurl}`}
                                        alt={recording.name}
                                        className=" w-full h-full object-contain object-center"
                                    />
                                </div>
                                <div className="md:w-[70%] w-full">
                                    <div className="flex w-full justify-between items-center sm:mb-5 mb-2 gap-3 flex-wrap">
                                        <h2 className="sm:mx-0 mx-auto 2xl:text-3xl xl:text-[19px] font-light font-inter tracking-[0.2em]">
                                            {recording.name}
                                        </h2>
                                        <button className="sm:mx-0 mx-auto px-4 py-2 border-2 border-black rounded-full flex items-center gap-2">
                                            Edit
                                        </button>
                                    </div>
                                    <div className="px-0 py-0 border-2 rounded-full relative overflow-hidden">
                                        <img
                                            src={sound1}
                                            className="w-full h-full left-0 top-0 absolute"
                                            alt="Sound Background"
                                        />
                                        <img
                                            src={boxup}
                                            className="w-1/2 h-full left-0 top-0 absolute"
                                            alt="Box Up"
                                        />
                                        <div id={`waveform-${index}`} className="waveform-container" style={{ height: '70px', padding: "5px" }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="md:mt-10 md:mb-5 mb-4 w-[80%] mx-auto">
                                <div className="h-1 w-full bg-white bg-opacity-50"></div>
                            </div>
                            <div className="flex justify-around flex-wrap sm:flex-nowrap px-4 w-full">
                                <div className="flex gap-3 mb-5 sm:flex-nowrap flex-wrap w-full">
                                    <button
                                        className="px-6 py-2 bg-[#3BE3E3] rounded-full w-full sm:w-[30%]"
                                        onClick={() => handleDownload(index)}>
                                        Download
                                    </button>
                                    <button
                                        className="px-6 py-2 bg-[#3BE3E3] rounded-full w-full sm:w-[30%]"
                                        onClick={() => handlePlayPause(index)}>
                                        {isPlaying[index] ? "Pause" : "Listen"}
                                    </button>
                                </div>
                                <button className="sm:w-[30%] w-full border-[#3BE3E3] rounded-full border px-6 py-2 mb-5">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No recordings available.</p>
            )}
        </div>
    );
}

export default Liftside;
