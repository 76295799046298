import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import person1 from "../../Assets/Images/person1.png";
import box from "../../Assets/Images/box.png";
import boxup from "../../Assets/Images/boxup.png";
import axios from "axios";
import Nav from "../Navbar/Nav";

export default function Campaigns() {
	const [search, setSearch] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [campaigns, setCampaigns] = useState([]);
	const [newCampaign, setNewCampaign] = useState({
		campaignId: null, // Added campaignId for update purpose
		campaignName: "",
		status: "",
		campaignImage: null,
		impressions: 0,
		clicks: 0,
	});
	const [isUpdating, setIsUpdating] = useState(false);

	const handleSearch = (e) => setSearch(e.target.value);

	useEffect(() => {
		// Perform the API call to fetch campaigns
		axios
			.get("https://inasnapmarketing.ai/api/campaign/getAllCampaign")
			.then((response) => {
				// Set the fetched campaigns to the state
				console.log(response.data);
				setCampaigns(response.data.campaigns);
				// console.log(campaigns)
			})

			.catch((error) => {
				// Handle any errors that occur during the API call
				console.error("Error fetching campaigns:", error);
			});
	}, []);
	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) setNewCampaign({ ...newCampaign, campaignImage: file });
	};
	console.log("outside ", campaigns);

	const handleCreateCampaign = async () => {
		const formData = new FormData();
		formData.append("campaignName", newCampaign.campaignName);
		formData.append("status", newCampaign.status);
		formData.append("impressions", newCampaign.impressions);
		formData.append("clicks", newCampaign.clicks);
		if (newCampaign.campaignImage) {
			formData.append("campaignImage", newCampaign.campaignImage);
		}

		for (let [key, value] of formData.entries()) {
			console.log(`${key}:`, value);
		}

		try {
			const response = await axios.post(
				"https://inasnapmarketing.ai/api/campaign/createCampaign",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			if (response.status === 200) {
				setCampaigns((prev) => [...prev, response.data]);
				setShowModal(false);
				resetForm();
			}
		} catch (error) {
			console.error("Error creating campaign:", error);
		}
	};

	const handleUpdateCampaign = async () => {
		const formData = new FormData();
		formData.append("campaignName", newCampaign.campaignName);
		formData.append("status", newCampaign.status);
		formData.append("impressions", newCampaign.impressions);
		formData.append("clicks", newCampaign.clicks);
		if (newCampaign.campaignImage) {
			formData.append("campaignImage", newCampaign.campaignImage);
		}

		try {
			const response = await axios.put(
				`https://inasnapmarketing.ai/api/campaign/updateCampaign/${newCampaign._id}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			if (response.status === 200) {
				setCampaigns((prev) =>
					prev.map((campaign) =>
						campaign.campaignId === newCampaign.campaignId
							? response.data
							: campaign
					)
				);
				setShowModal(false);
				window.location.reload();
				resetForm();
			}
		} catch (error) {
			console.error("Error updating campaign:", error);
		}
	};
	const handleDeleteCMP = async (id) => {
		try {
			// Sending DELETE request to the backend
			const response = await axios.delete(
				`https://inasnapmarketing.ai/api/campaign/deleteCampaign/${id}`
			);

			// Handle successful response
			if (response.status === 200) {
				console.log("Campaign deleted successfully:", response.data);
				window.location.reload();
				// Optionally update your UI or state here
			}
		} catch (error) {
			// Handle errors
			console.error("Error deleting campaign:", error);
			// Optionally display an error message to the user
		}
	};

	const resetForm = () => {
		setNewCampaign({
			campaignId: null,
			campaignName: "",
			status: "",
			campaignImage: null,
			impressions: 0,
			clicks: 0,
		});
		setIsUpdating(false);
	};

	const openUpdateModal = (campaign) => {
		setNewCampaign(campaign);
		setShowModal(true);
		setIsUpdating(true);
	};
	// Log whenever campaigns change

	const filteredCampaigns = campaigns.filter((campaigns) =>
		campaigns?.saleName?.toLowerCase()?.includes(search?.toLowerCase())
	);

	console.log("filter", filteredCampaigns);

	return (
		<div className="w-full overflow-hidden">
			<Nav pagename="Agents" person1={person1} />

			{/* Search and Add Campaign Section */}
			<div className="text-white md:px-10 px-4 py-5 gap-5 flex justify-between items-center flex-wrap">
				<div>
					<h2 className="font-inter text-2xl mb-3">Manage Campaign</h2>
					<p className="sm:text-[20px] text-[17px] text-white text-opacity-50 font-thin">
						Manage your campaigns in one place.
					</p>
				</div>
				<div className="flex gap-4 flex-wrap sm:my-0 my-4">
					<div className="relative">
						<div className="absolute top-3 left-3">
							<Icon
								icon="lucide:search"
								width="2rem"
								height="2rem"
								style={{ color: "black" }}
							/>
						</div>
						<input
							type="text"
							placeholder="Search Campaign"
							value={search}
							onChange={handleSearch}
							className="md:px-16 ps-16 h-14 w-full outline-none border-none text-black font-inter"
						/>
					</div>
					<button
						className="h-14 px-6 gap-1 bg-[#3BE3E3] text-black font-inter text-[18px] flex justify-center items-center"
						onClick={() => setShowModal(true)}>
						<Icon
							icon="f7:plus"
							width="1.6rem"
							height="1.6rem"
							style={{ color: "black" }}
						/>
						New Campaign
					</button>
				</div>
			</div>

			{/* Campaign Cards */}
			<div className="grid xl:grid-cols-2 grid-cols-1 gap-5 px-4 md:px-10 mt-10 2xl:gap-16 xl:gap-10 border-b-[0.5px] border-white border-opacity-50 pb-10">
				{filteredCampaigns.map((campaign, index) => (
					<div
						key={index}
						className="w-[100%] sm:w-full min-h-[300px] rounded-[30px] relative z-10 overflow-hidden">
						<img src={box} className="w-full h-full absolute -z-[9]" alt="" />
						<img src={boxup} className="h-full absolute left-0 -z-[9]" alt="" />
						<div className="w-full md:px-12 px-4 py-6 flex justify-between items-center flex-wrap">
							<div className="flex items-center sm:gap-5 gap-2">
								<div className="md:w-14 w-6 h-auto">
									<img
										className="w-full h-full"
										alt=""
										src={`https://inasnapmarketing.ai/api/${campaign.saleImage}`}
									/>
								</div>
								<h2 className="md:text-3xl text-1xl font-inter text-white font-thin">
									{campaign.saleName}
								</h2>
							</div>
							<button
								className="ms-auto my-2 border-4 border-black text-[14px] text-white sm:px-4 px-2 rounded-2xl py-[6px] flex items-center sm:gap-2 gap-1"
								onClick={() => openUpdateModal(campaign)}>
								<Icon icon="hugeicons:pencil-edit-02" />
								Edit
							</button>
						</div>
						<div className="flex md:px-12 px-4 items-center sm:gap-10 gap-2 text-white">
							<h2 className="md:text-2xl sm:text-[18px] text-[16px] font-inter">
								Status:
							</h2>
							<p className="text-[#9D9292] md:text-2xl sm:text-[18px] text-[16px] font-normal">
								{campaign.status}
							</p>
						</div>
						<div className="flex md:px-12 px-4 items-center sm:gap-10 gap-2 text-white mt-4">
							<h2 className="md:text-2xl sm:text-[18px] text-[16px] font-inter">
								Insight:
							</h2>
							<div className="text-[#9D9292] md:text-[20px] sm:text-[18px] text-[12px] font-normal flex flex-wrap">
								<div>
									Impressions:{" "}
									<span className="px-3">{campaign.Impressions}</span>
								</div>
								<div>
									<span className="pe-3">|</span> Clicks:{" "}
									<span className="ps-3">{campaign.clicks}</span>
								</div>
							</div>
						</div>
						<div className="h-[2px] w-[90%] mx-auto my-5 bg-white"></div>
						<div className="md:px-12 px-4 flex justify-around flex-wrap pb-6">
							<button className="text-[13px] sm:text-[16px] px-10 py-3 bg-[#0B9E9E] rounded-xl text-white">
								Archive
							</button>
							<button
								onClick={() => handleDeleteCMP(campaign._id)}
								className="text-[13px] sm:text-[16px] px-10 py-3 bg-[#0B9E9E] rounded-xl text-white">
								Delete
							</button>
						</div>
					</div>
				))}
			</div>

			{/* Modal for Creating/Updating Campaign */}
			{showModal && (
				<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
					<div className="bg-white p-6 rounded-lg">
						<h2 className="text-xl mb-4">
							{isUpdating ? "Update Campaign" : "Create New Campaign"}
						</h2>
						{/* Form Fields */}
						<input
							type="text"
							placeholder="Campaign Name"
							value={newCampaign.campaignName}
							onChange={(e) =>
								setNewCampaign({ ...newCampaign, campaignName: e.target.value })
							}
							className="w-full mb-4 p-2 border border-gray-300 rounded"
						/>
						{/* Other form fields... */}
					

						<div className="mt-4">
							<label className="block text-sm font-medium text-gray-700">
								Status
							</label>
							<select
								value={newCampaign.status}
								onChange={(e) =>
									setNewCampaign({ ...newCampaign, status: e.target.value })
								}
								className="w-full mt-2 p-2 border border-gray-300 rounded">
								<option value="">Select Status</option>
								<option value="active">Active</option>
								<option value="inactive">Inactive</option>
							</select>
						</div>

						<div className="mt-4">
							<label className="block text-sm font-medium text-gray-700">
								Impressions
							</label>
							<input
								type="number"
								placeholder="Impressions"
								value={newCampaign.impressions}
								onChange={(e) =>
									setNewCampaign({
										...newCampaign,
										impressions: e.target.value,
									})
								}
								className="w-full mt-2 p-2 border border-gray-300 rounded"
							/>
						</div>

						<div className="mt-4">
							<label className="block text-sm font-medium text-gray-700">
								Clicks
							</label>
							<input
								type="number"
								placeholder="Clicks"
								value={newCampaign.clicks}
								onChange={(e) =>
									setNewCampaign({
										...newCampaign,
										clicks: e.target.value,
									})
								}
								className="w-full mt-2 p-2 border border-gray-300 rounded"
							/>
						</div>

						<div className="mt-4">
							<label className="block text-sm font-medium text-gray-700">
								Campaign Image
							</label>
							<input
								type="file"
								onChange={handleImageChange}
								className="w-full mt-2 p-2 border border-gray-300 rounded"
							/>
						</div>

						{/* Buttons */}
						<div className="mt-6 flex justify-end">
							<button
								onClick={
									isUpdating ? handleUpdateCampaign : handleCreateCampaign
								}
								className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
								{isUpdating ? "Update" : "Create"}
							</button>
							<button
								onClick={() => setShowModal(false)}
								className="ml-2 px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400">
								Cancel
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
