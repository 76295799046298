import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import person1 from "../../Assets/Images/person1.png";
import Nav from "../Navbar/Nav.jsx";

export default function Agents() {
	const [search, setSearch] = useState("");
	const [agents, setAgents] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [error, setError] = useState("");
	const [newAgent, setNewAgent] = useState({
		name: "",
		email: "",
		image: null,
	});
	const [selectedAgentId, setSelectedAgentId] = useState(null);

	useEffect(() => {
		const fetchAgents = async () => {
			try {
				const response = await axios.get("https://inasnapmarketing.ai/api/agent/getAllAgent");
				setAgents(response.data.agents);
				console.log(response , "response")
			} catch (error) {
				console.error("Failed to fetch agents:", error);
			}
		};
		fetchAgents();
	}, []);

	const filteredAgents = agents.filter((agent) =>
		agent.name.toLowerCase().includes(search.toLowerCase())
	);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewAgent((prev) => ({ ...prev, [name]: value }));
		setError("");
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setNewAgent((prev) => ({ ...prev, image: file }));
			setError("");
		}
	};

	const validateEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const openEditModal = (agent) => {
		setEditMode(true);
		setShowModal(true);
		setNewAgent({ name: agent.name, email: agent.email, image: agent.image });
		setSelectedAgentId(agent._id);
	};

	const addNewAgent = async () => {
		if (!newAgent.name || !newAgent.email || !newAgent.image) {
			setError("Please fill in all required fields.");
			return;
		}
		if (!validateEmail(newAgent.email)) {
			setError("Please enter a valid email address.");
			return;
		}

		try {
			const formData = new FormData();
			formData.append("name", newAgent.name);
			formData.append("email", newAgent.email);
			formData.append("image", newAgent.image);

			const response = await axios.post(
				"https://inasnapmarketing.ai/api/agent/createAgent",
				formData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				}
			);

			setAgents([...agents, response.data.agent]);
			setNewAgent({ name: "", email: "", image: null });
			setShowModal(false);
			setSuccessMessage("Agent added successfully!");
			setTimeout(() => setSuccessMessage(""), 3000);
		} catch (error) {
			console.error("Failed to add agent:", error);
		}
	};

	const updateAgent = async () => {
		if (!newAgent.name || !newAgent.email) {
			setError("Please fill in all required fields.");
			return;
		}
		if (!validateEmail(newAgent.email)) {
			setError("Please enter a valid email address.");
			return;
		}

		try {
			const formData = new FormData();
			formData.append("name", newAgent.name);
			formData.append("email", newAgent.email);
			if (newAgent.image) formData.append("image", newAgent.image);

			const response = await axios.put(
				`https://inasnapmarketing.ai/api/agent/updateAgent/${selectedAgentId}`,
				formData,
				{
					headers: { "Content-Type": "multipart/form-data" },
				}
			);

			const updatedAgents = agents.map((agent) =>
				agent._id === selectedAgentId ? response.data.agent : agent
			);

			setAgents(updatedAgents);
			setNewAgent({ name: "", email: "", image: null });
			setShowModal(false);
			setEditMode(false);
			setSuccessMessage("Agent updated successfully!");
			setTimeout(() => setSuccessMessage(""), 3000);
		} catch (error) {
			console.error("Failed to update agent:", error);
		}
	};

	

	return (
		<div className="text-white w-full">
			<Nav pagename="Agents" person1={person1} />

			{successMessage && (
				<div className="fixed top-5 right-5 bg-green-500 text-white px-4 py-2 rounded-md">
					{successMessage}
				</div>
			)}

			{error && (
				<div className="fixed top-5 right-5 bg-red-500 text-white px-4 py-2 rounded-md">
					{error}
				</div>
			)}

			<div className="md:px-10 px-4 py-5 flex justify-between items-center flex-wrap">
				<div>
					<h2 className="font-inter text-2xl mb-3">Manage Agents</h2>
					<p className="text-[20px] text-white text-opacity-50 font-thin">
						Manage your agents in one place.
					</p>
				</div>
				<div className="flex gap-4 flex-wrap sm:my-0 my-4">
					<div className="relative">
						<div className="absolute top-3 left-3">
							<Icon icon="lucide:search" width="2rem" height="2rem" style={{ color: "black" }} />
						</div>
						<input
							type="text"
							placeholder="Search Agents"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							className="md:px-16 ps-16 h-14 w-full outline-none border-none text-black font-inter"
						/>
					</div>
					<button
						className="h-14 mywid px-6 gap-1 bg-[#3BE3E3] text-black font-inter text-[18px] flex justify-center items-center"
						onClick={() => {
							setShowModal(true);
							setEditMode(false);
							setNewAgent({ name: "", email: "", image: null });
						}}>
						<Icon icon="f7:plus" width="1.6rem" height="1.6rem" style={{ color: "black" }} />
						New Agent
					</button>
				</div>
			</div>

			<div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 md:px-6 px-4">
				{filteredAgents.map((agent, index) => (
					<div key={index} className="mx-auto text-center bg-[#202125] w-full pt-8 pb-3">
						<div className="w-28 h-28 rounded-full bg-red-100 mx-auto">
							<img
								src={agent.image ? `http://localhost:3002${agent.image}` : person1}
								alt={agent.name}
								className="w-full h-full object-cover rounded-full"
							/>
						</div>
						<h2 className="font-inter text-2xl mt-2">{agent.name}</h2>
						<p className="text-[15px] mt-[-2px] mb-2 text-white text-opacity-50 font-thin">
							View Profile
						</p>
						<button
							className="text-[#07C36F] py-2 border-[3px] rounded-sm border-[#07C36F] w-[90%]"
							onClick={() => openEditModal(agent)}>
							Edit Details
						</button>
					</div>
				))}
			</div>

			{/* Add/Edit Agent Modal */}
			{showModal && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
					<div className="bg-white p-6 rounded-md  m-2 w-full  md:w-1/3 text-black">
						<h2 className="text-xl font-bold mb-4">{editMode ? "Edit Agent" : "Add New Agent"}</h2>
						<div className="mb-4">
							{error && <p className="text-red-500">{error}</p>}
							<input
								type="text"
								name="name"
								value={newAgent.name}
								onChange={handleInputChange}
								placeholder="Agent Name"
								className="w-full h-10 border rounded-md px-3"
							/>
						</div>
						<div className="mb-4">
							<input
								type="email"
								name="email"
								value={newAgent.email}
								onChange={handleInputChange}
								placeholder="Agent Email"
								className="w-full h-10 border rounded-md px-3"
							/>
						</div>
						<div className="mb-4">
							<input
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								className="w-full h-10 border rounded-md px-3"
							/>
						</div>
						<button
							onClick={editMode ? updateAgent : addNewAgent}
							className="bg-green-500 text-white px-4 py-2 rounded-md">
							{editMode ? "Update" : "Add"} Agent
						</button>
						<button
							onClick={() => setShowModal(false)}
							className="bg-red-500 text-white px-4 py-2 rounded-md ml-2">
							Cancel
						</button>
					</div>
				</div>
			)}
			{filteredAgents.length < 0  &&  <p>agent is not show</p>}
		</div>
	);
}
