import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";

export default function Login() {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	// List of valid credentials
	const validCredentials = [
		{ email: "test@example.com", password: "password123" },
		{ email: "jenn.e.matthews@gmail.com", password: "Mathew123" },
		{ email: "inasnapleads@gmail.com", password: "Thomas123" },
		{ email: "wrightkoke@gmail.com", password: "Wright123" },
	];

	const login = (e) => {
		e.preventDefault(); // Prevent default form submission behavior

		// Check if the entered email and password exist in the validCredentials array
		const isValidUser = validCredentials.some(
			(cred) => cred.email === email && cred.password === password
		);

		if (isValidUser) {
			localStorage.setItem("login", true);
			navigate("/");
		} else {
			setError("Invalid email or password. Please try again.");
		}
	};

	return (
		<div className="w-full h-screen flex justify-center items-center bg-black">
			<form className="form border-2 border-[#55b980] p-6 py-6 rounded-md" onSubmit={login}>
				{error && <p className="text-red-500 mb-4">{error}</p>}
				<span className="input-span">
					<label htmlFor="email" className="label">
						Email
					</label>
					<input
						type="email"
						name="email"
						id="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="text-white"
					/>
				</span>
				<span className="input-span mb-6">
					<label htmlFor="password" className="label">
						Password
					</label>
					<input
						type="password"
						name="password"
						id="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						className="text-white"
					/>
				</span>
				<input className="submit mt-5" type="submit" value="Log in" />
			</form>
		</div>
	);
}
