import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import person1 from "../../Assets/Images/person1.png";
import BarChart from "./Chart";
import Table from "./Table/Table";
import Chart2 from "./Chart2";
import AreaChart3 from "./Chart3";
import SemiDonutChart from "./PieChart";
import "./admin.css";
import Nav from "../Navbar/Nav";
import axios from "axios";

export default function Admin() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    percentage: '',
    sales: ''
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    password: '',
    percentage: '',
    sales: ''
  });
  const[admin , setAdmin] = useState([])
  const [searchTerm, setSearchTerm] = useState(""); // State for search input


  useEffect(() => {
	const fetchGoals = async () => {
		try {
			const response = await axios.get("https://inasnapmarketing.ai/api/admin/getallAdmin");
			console.log(response.data)
			setAdmin(response.data); // Assuming the API returns an array of goals
		} catch (error) {
			console.error("Error fetching goals:", error);
		}
	};
	fetchGoals();
}, []);


const filteredAdmins = admin.filter(admin => {
    return (
      admin.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      admin.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
console.log("admin" , admin)
  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Clear error message on input change
    setFormErrors(prevState => ({
      ...prevState,
      [name]: ''
    }));
  };

  // Handle modal close
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Email validation
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  // Handle form submission
  const handleSubmit = async () => {
    const errors = {};

    // Check for empty fields and validate email
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    else if (!validateEmail(formData.email)) errors.email = "Invalid email format";
    if (!formData.password) errors.password = "Password is required";
    if (!formData.percentage) errors.percentage = "Completion percentage is required";
    if (!formData.sales) errors.sales = "Sales is required";

    // If there are errors, update the formErrors state and don't submit
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // If no errors, submit the form
    try {
      const response = await axios.post('https://inasnapmarketing.ai/api/admin/CreateAdmin', formData); // Assuming the backend API is at '/api/admin'
      console.log('Success:', response.data);
      closeModal();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Set the search term from input field
  };

  return (
    <div className="bg-red-000 w-full overflow-hidden">
      <Nav person1={person1} pagename="Admin" />
      <div className="md:px-10 px-4 py-5 flex flex-wrap justify-between items-center">
        <div>
          <h2 className="font-inter text-2xl mb-3 text-white">Admin Dashboard</h2>
        </div>
        <div className="flex gap-4 flex-wrap">
          <div className="relative">
            <div className="absolute top-3 left-3">
              <Icon
                icon="lucide:search"
                width="2rem"
                height="2rem"
                style={{ color: "black" }}
              />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="w-full px-16 h-14 outline-none border-none text-black font-inter"
              value={searchTerm} // Bind the input value to the search term state
              onChange={handleSearchChange} // Update the search term on input change
            />
          </div>
          <button
            onClick={() => setIsModalOpen(true)} // This opens the modal
            className="h-14 px-6 gap-1 bg-[#3BE3E3] text-black font-inter text-[18px] flex justify-center items-center"
          >
            <Icon
              icon="f7:plus"
              width="1.6rem"
              height="1.6rem"
              style={{ color: "black" }}
            />
            New Admin
          </button>
        </div>
      </div>
	  <div className="grid px-4 grid-cols-12 gap-4 font-inter text-white md:px-10 overflow-x-auto w-full overflow-hidden">
				<div className="xl:col-span-6 lg:col-span-12 md:col-span-12 col-span-12 overflow-hidden  w-[100%] sm:w-[100%] ">
					<div className="bg-[#202125] px-4 py-6 rounded-md flex flex-col">
						<div className="text-white my-4">
							<h2 className="text-2xl">Today’s Sales</h2>
							<p className="text-[15px] text-opacity-50  text-white">
								Sales Summary
							</p>
						</div>
						<div className="grid lg:grid-cols-4 xl:grid-cols-2 2xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 grid-cols-1   gap-3 ">
							<div className=" w-full bg-[#171821] rounded-md flex flex-col">
								<div className=" px-3 py-3  ">
									<Icon
										icon="hugeicons:analytics-01"
										style={{ color: "#FEB95A" }}
										className=" text-5xl "
									/>

									<h2 className="text-3xl my-2">$5k</h2>
									<p className="text-[14px]">Total Sales</p>
									<p className="text-[12px] text-[#FEB95A]">
										+10% from yesterday
									</p>
								</div>
							</div>
							<div className=" w-full bg-[#171821] rounded-md ">
								<div className=" px-3 py-3 ">
									<Icon
										icon="teenyicons:battery-charge-outline"
										style={{ color: "#A9DFD8" }}
										className="text-5xl -rotate-90"
									/>
									<h2 className="text-3xl my-2">500</h2>
									<p className="text-[14px]">Total Order</p>
									<p className="text-[12px] text-[#A9DFD8]">
										+10% from yesterday
									</p>
								</div>
							</div>
							<div className=" w-full bg-[#171821] rounded-md ">
								<div className=" px-3 py-3 ">
									<Icon
										icon="iconoir:shopping-bag-arrow-down"
										style={{ color: "#F2C8ED" }}
										className="text-5xl"
									/>
									<h2 className="text-3xl my-2">9</h2>
									<p className="text-[14px]">Product Sold</p>
									<p className="text-[12px] text-[#F2C8ED]">
										+2% from yesterday
									</p>
								</div>
							</div>
							<div className=" w-full bg-[#171821] rounded-md ">
								<div className=" px-3 py-3 ">
									<Icon
										icon="ant-design:user-add-outlined"
										style={{ color: "#20AEF3" }}
										className="text-5xl "
									/>
									<h2 className="text-3xl my-2">12</h2>
									<p className="text-[14px]">New Customer</p>
									<p className="text-[12px] text-[#20AEF3]">
										+3% from yesterday
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className=" my-2 w-100  ">
						<Table filteredAdmins={filteredAdmins} />
					</div>
				</div>
				<div className="xl:col-span-6 lg:col-span-12 md:col-span-12 col-span-12 ">
					<BarChart />
				</div>
				<div className="xl:col-span-6 lg:col-span-12 md:col-span-12 col-span-12 ">
					<Chart2 />
				</div>
				<div className="xl:col-span-6 lg:col-span-12 md:col-span-12 col-span-12  w-full  gap-6 mt-8 mx-auto  grid md:grid-cols-2 grid-cols-1">
					<AreaChart3 />

					<SemiDonutChart />
				</div>
			</div>


      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-2xl font-semibold mb-4">Add New Admin</h2>

            {/* Modal Inputs */}
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className={`w-full mb-3 p-2 border ${formErrors.name ? 'border-red-500' : 'border-gray-300'} rounded`}
            />
            {formErrors.name && <p className="text-red-500 text-sm">{formErrors.name}</p>}

            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full mb-3 p-2 border ${formErrors.email ? 'border-red-500' : 'border-gray-300'} rounded`}
            />
            {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}

            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              className={`w-full mb-3 p-2 border ${formErrors.password ? 'border-red-500' : 'border-gray-300'} rounded`}
            />
            {formErrors.password && <p className="text-red-500 text-sm">{formErrors.password}</p>}

            <input
              type="number"
              name="percentage"
              placeholder="Completion Percentage (%)"
              value={formData.percentage}
              onChange={handleChange}
              className={`w-full mb-3 p-2 border ${formErrors.percentage ? 'border-red-500' : 'border-gray-300'} rounded`}
              min="0"
              max="100"
            />
            {formErrors.percentage && <p className="text-red-500 text-sm">{formErrors.percentage}</p>}

            <input
              type="number"
              name="sales"
              placeholder="Sales"
              value={formData.sales}
              onChange={handleChange}
              className={`w-full mb-3 p-2 border ${formErrors.sales ? 'border-red-500' : 'border-gray-300'} rounded`}
            />
            {formErrors.sales && <p className="text-red-500 text-sm">{formErrors.sales}</p>}

            {/* Modal Footer */}
            <div className="flex justify-end gap-2">
              <button onClick={closeModal} className="px-4 py-2 bg-gray-300 rounded">
                Cancel
              </button>
              <button onClick={handleSubmit} className="px-4 py-2 bg-[#3BE3E3] text-black rounded">
                Save
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
}
