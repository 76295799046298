import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import person1 from "../../Assets/Images/person1.png";
import Nav from "../Navbar/Nav.jsx";
import axios from "axios";

export default function List() {
	const [search, setSearch] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [agents, setAgents] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [newAgent, setNewAgent] = useState({
		name: "",
		subscribers: 0,
		totalContacts: 0,
	});
	const [selectedAgentId, setSelectedAgentId] = useState(null);
	const [successMessage, setSuccessMessage] = useState(""); // Success message state
	const rowsPerPage = 8;

	// Fetch agents from the API
	useEffect(() => {
		const fetchAgents = async () => {
			try {
				const response = await axios.get(
					"https://inasnapmarketing.ai/api/agentList/agentsListGet"
				);
				console.log("Response received:", response.data.agentList); // Logs the full response
				setAgents(response.data.agentList);
			} catch (error) {
				console.error("Error fetching agents:", error);
				setAgents([]); // Fallback to empty array on error
			}
		};
		fetchAgents();
	}, []); // Empty dependency array to run once on mount

	const filteredAgents = agents.filter((agent) => {
		// Check if agent is defined and has a lastName property
		return (
			agent &&
			agent.lastName &&
			agent.lastName.toLowerCase().includes(search.toLowerCase())
		);
	});

	// Pagination logic
	const indexOfLastRow = currentPage * rowsPerPage;
	const indexOfFirstRow = indexOfLastRow - rowsPerPage;

	const currentAgents =
		filteredAgents.length > 0
			? filteredAgents.slice(indexOfFirstRow, indexOfLastRow)
			: [];
	const totalPages = Math.ceil(filteredAgents.length / rowsPerPage);

	const handleNextPage = () => {
		if (currentPage < totalPages) setCurrentPage(currentPage + 1);
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	// Handle new agent creation
	const handleAddAgent = async () => {
		if (newAgent.name.trim() === "") return;

		try {
			const response = await axios.post(
				"https://inasnapmarketing.ai/api/agentList/createAgentsList",
				newAgent
			);
			setAgents([...agents, response.data]);
			setNewAgent({ name: "", subscribers: 0, totalContacts: 0 });
			setShowAddModal(false);
			setSuccessMessage("Agent created successfully!"); // Show success message
			setTimeout(() => setSuccessMessage(""), 3000); // Clear message after 3 seconds
		} catch (error) {
			console.error("Error adding agent:", error);
		}
	};

	const handleDeleteAgent = async (id) => {
		try {
			await axios.delete(
				`https://inasnapmarketing.ai/api/agentList/agentsListDelete/${id}`
			);
			setAgents(agents.filter((agent) => agent._id !== id));
			setSuccessMessage("Agent deleted successfully!"); // Show success message
			setTimeout(() => setSuccessMessage(""), 3000); // Clear message after 3 seconds
		} catch (error) {
			console.error("Error deleting agent:", error);
		}
	};

	const openUpdateModal = (agent) => {
		setSelectedAgentId(agent._id);
		console.log(selectedAgentId);
		setNewAgent({
			name: agent.lastName,
			subscribers: agent.totalSubscribers,
			totalContacts: agent.contact,
		});
		setShowUpdateModal(true);
	};

	const updateAgent = async () => {
		console.log("clicked", selectedAgentId);
		if (!newAgent.name) {
			return;
		}

		try {
			const response = await axios.put(
				`https://inasnapmarketing.ai/api/agentList/agentsListUpdate/${selectedAgentId}`,
				newAgent
			);
			const updatedAgents = agents.map((agent) =>
				agent._id === selectedAgentId ? response.data.agent : agent
			);

			setAgents(updatedAgents);
			setNewAgent({ name: "", subscribers: 0, totalContacts: 0 });
			setShowUpdateModal(false);
			setSuccessMessage("Agent updated successfully!"); // Show success message
			setTimeout(() => setSuccessMessage(""), 3000); // Clear message after 3 seconds
		} catch (error) {
			console.error("Failed to update agent:", error);
		}
	};

	return (
		<div>
			<Nav person1={person1} pagename="Lists" />
			<div className="md:px-10 px-4 py-5 flex justify-between items-center flex-wrap text-white">
				<div>
					<h2 className="font-inter text-2xl mb-3">Manage Agents</h2>
					<p className="text-[20px] text-white text-opacity-50 font-thin">
						Manage Contacts
					</p>
				</div>
				<div className="flex gap-4 flex-wrap sm:my-0 my-4">
					<div className="relative">
						<div className="absolute top-3 left-3">
							<Icon
								icon="lucide:search"
								width="2rem"
								height="2rem"
								style={{ color: "black" }}
							/>
						</div>
						<input
							type="text"
							placeholder="Search Agents"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							className="md:px-16 ps-16 h-14 w-full outline-none border-none text-black font-inter"
						/>
					</div>
					<button
						className="h-14 mywid px-6 gap-1 bg-[#3BE3E3] text-black font-inter text-[18px] flex justify-center items-center"
						onClick={() => setShowAddModal(true)}>
						<Icon
							icon="f7:plus"
							width="1.6rem"
							height="1.6rem"
							style={{ color: "black" }}
						/>
						Add New list
					</button>
				</div>
			</div>

			<h2 className="font-inter text-2xl px-5 text-white">Applications List</h2>
			<div className="px-5">
				<div className="w-full border-[0.5px] border-white border-opacity-50 rounded-lg mt-5 overflow-x-auto">
					<table
						className="lg:w-full md:w-[150%] w-[200%] text-white table-auto"
						style={{ borderCollapse: "collapse" }}>
						<thead>
							<tr className="border-b-[0.5px] border-white border-opacity-50">
								<th className="text-left px-4 py-6">Last Name</th>
								<th className="text-left px-4 py-6 border-l-[0.5px] border-white border-opacity-50">
									Total Contacts
								</th>
								<th className="text-left px-4 py-6 border-l-[0.5px] border-white border-opacity-50">
									Total Subscribers
								</th>
								<th className="text-left px-4 py-6 border-l-[0.5px] border-white border-opacity-50">
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							{currentAgents.map((agent, index) => (
								<tr
									key={index}
									className={`border-white border-opacity-50 ${
										index === currentAgents.length - 1 ? "" : "border-b-[0.5px]"
									}`}>
									<td className="px-4 py-3">{agent.lastName}</td>
									<td className="px-4 py-3">
										<div className="w-full bg-gray-200 h-2 rounded-lg">
											<div
												className="bg-blue-600 h-full rounded-lg"
												style={{
													width: `${(agent.contact / 10000) * 100}%`,
												}}
											/>
										</div>
										<p className="text-white mt-2 text-start">
											{agent.contact}
										</p>
									</td>
									<td className="px-4 py-3">{agent.totalSubscribers}</td>
									<td className="px-4 py-3 flex gap-2 flex-wrap">
										<button
											onClick={() => handleDeleteAgent(agent._id)}
											className="bg-red-500 text-white px-2 rounded">
											Del
										</button>
										<button
											onClick={() => openUpdateModal(agent)}
											className="bg-blue-500 text-white px-2 rounded">
											Upd
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>

				{/* Pagination */}
				<div className="flex justify-between gap-2 rounded-lg items-center mt-5 border-2 border-white border-opacity-50 py-3 px-3">
					<button
						onClick={handlePreviousPage}
						disabled={currentPage === 1}
						className={`flex items-center gap-4 px-4 py-2  text-white rounded ${
							currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
						}`}>
						<Icon icon="icon-park-outline:arrow-left" />
						<button className="hidden sm:block">Previous</button>
					</button>

					{/* Active Page with red background */}
					<span
						className={`text-black px-4 py-2 rounded ${
							currentPage ? "bg-[#28aff3]" : ""
						}`}>
						{currentPage}
					</span>

					<button
						onClick={handleNextPage}
						disabled={currentPage === totalPages}
						className={` flex  justify-center items-center gap-4 px-4 py-2  text-white rounded ${
							currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
						}`}>
						<button className="hidden sm:block">Next</button>

						<div className="mt-[1px]">
							<Icon icon="icon-park-outline:arrow-right" />
						</div>
					</button>
				</div>

				{/* Success message display */}
				{successMessage && (
					<div className="bg-green-500 text-white text-center p-3 rounded mt-4">
						{successMessage}
					</div>
				)}

				{/* Add Agent Modal */}
				{showAddModal && (
					<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
						<div className="bg-white rounded-lg p-6">
							<h2 className="text-xl font-bold">Add New Agent</h2>
							<input
								type="text"
								placeholder="Agent Name"
								value={newAgent.name}
								onChange={(e) =>
									setNewAgent({ ...newAgent, name: e.target.value })
								}
								className="border border-gray-300 p-2 rounded mt-2 w-full"
							/>
							<input
								type="number"
								placeholder="Subscribers"
								value={newAgent.subscribers}
								onChange={(e) =>
									setNewAgent({ ...newAgent, subscribers: +e.target.value })
								}
								className="border border-gray-300 p-2 rounded mt-2 w-full"
							/>
							<input
								type="number"
								placeholder="Total Contacts"
								value={newAgent.totalContacts}
								onChange={(e) =>
									setNewAgent({ ...newAgent, totalContacts: +e.target.value })
								}
								className="border border-gray-300 p-2 rounded mt-2 w-full"
							/>
							<div className="flex justify-end mt-4">
								<button
									onClick={() => setShowAddModal(false)}
									className="bg-gray-300 px-4 py-2 rounded">
									Cancel
								</button>
								<button
									onClick={handleAddAgent}
									className="bg-blue-500 text-white px-4 py-2 rounded ml-2">
									Add
								</button>
							</div>
						</div>
					</div>
				)}

				{/* Update Agent Modal */}
				{showUpdateModal && (
					<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
						<div className="bg-white rounded-lg p-6">
							<h2 className="text-xl font-bold">Update Agent</h2>
							<input
								type="text"
								placeholder="Agent Name"
								value={newAgent.name}
								onChange={(e) =>
									setNewAgent({ ...newAgent, name: e.target.value })
								}
								className="border border-gray-300 p-2 rounded mt-2 w-full"
							/>
							<input
								type="number"
								placeholder="Subscribers"
								value={newAgent.subscribers}
								onChange={(e) =>
									setNewAgent({ ...newAgent, subscribers: +e.target.value })
								}
								className="border border-gray-300 p-2 rounded mt-2 w-full"
							/>
							<input
								type="number"
								placeholder="Total Contacts"
								value={newAgent.totalContacts}
								onChange={(e) =>
									setNewAgent({ ...newAgent, totalContacts: +e.target.value })
								}
								className="border border-gray-300 p-2 rounded mt-2 w-full"
							/>
							<div className="flex justify-end mt-4">
								<button
									onClick={() => setShowUpdateModal(false)}
									className="bg-gray-300 px-4 py-2 rounded">
									Cancel
								</button>
								<button
									onClick={updateAgent}
									className="bg-blue-500 text-white px-4 py-2 rounded ml-2">
									Update
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
