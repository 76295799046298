
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import person1 from "../../Assets/Images/person1.png";
import Nav from "../Navbar/Nav";
import { ReactMic } from "react-mic";
import record2 from "../../Assets/Images/recordinimg.png";
import sound1 from "../../Assets/Images/sound1.png"; // Import the audio file
import boxup from "../../Assets/Images/boxup.png";
import Right from "./Right";
import righboximg from "../../Assets/Images/bigcricle.png";
import Liftside from "./Leftside";


export default function Record() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingName, setRecordingName] = useState("");
  const [recordingImage, setRecordingImage] = useState(null);
  const [recordedAudio, setRecordedAudio] = useState(null); // State for recorded audio
  const [audioPreviewUrl, setAudioPreviewUrl] = useState(null); // Audio preview URL state
  const[data , setDate ] = useState([])
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!recordingName) newErrors.recordingName = "Recording name is required.";
    if (!recordingImage) newErrors.recordingImage = "Recording image is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleStartRecording = () => {
    if (validateForm()) {
      setIsRecording(true);
    }
  };

  const handleStopRecording = (recordedBlob) => {
    setIsRecording(false);
    if (recordedBlob) {
      setRecordedAudio(recordedBlob); // Save the recorded blob to state
      const audioUrl = URL.createObjectURL(recordedBlob.blob); // Create audio URL for preview
      setAudioPreviewUrl(audioUrl); // Save audio preview URL
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setRecordingImage(file); // Save the file object
    }
  };

  const handleSubmit = async () => {
  if (recordedAudio && recordingName && recordingImage) {
    try {
      const formData = new FormData();
      formData.append("recordingName", recordingName);
      formData.append("recordingImage", recordingImage); // Append the image file

      // Ensure recordedAudio is sent as a file (with a file name and MIME type)
      const audioBlob = recordedAudio.blob;
      const audioFile = new File([audioBlob], "recording.wav", { type: audioBlob.type });
      formData.append("audio", audioFile); // Append audio as a file

      // Log the form data for debugging
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const response = await axios.post("https://inasnapmarketing.ai/api/recording/createRecording", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.status , "stutes code")

      if (response.status === 201) {
        alert("Recording submitted successfully!");
        setIsModalOpen(false);
        setRecordingName("");
        setRecordingImage(null);
        setRecordedAudio(null);
        setAudioPreviewUrl(null); // Clear audio preview URL
      } else {
        alert("Failed to submit recording.");
      }
    } catch (error) {
      alert("An error occurred while submitting the recording.");
      console.error(error);
    }
  } else {
    alert("Please complete the form before submitting.");
  }
};
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get("https://inasnapmarketing.ai/api/recording/getRecordings");
      setDate(response.data.recordings);  // Updated setDate to setData, assuming that's the intended function
      console.log(response.data.recordings);
    } catch (error) {
      console.log("Error: " + error);
    }
  };

  fetchData();
}, []);

console.log("data",data);



  const handleCancel = () => {
    setRecordingName("");
    setRecordingImage(null);
    setRecordedAudio(null);
    setAudioPreviewUrl(null); // Clear audio preview URL
    setIsModalOpen(false);
  };


  return (
    <div className="p-4">
      {/* Header Section */}
      <Nav person1={person1} pagename="Recordings" />

      <div className="md:px-10 px-4 py-5 flex  flex-wrap justify-between items-center">
				<div className=" my-3">
					<h2 className="font-inter sm:text-2xl text-[20px] mb-3 text-white">
						Manage Recordings
					</h2>
					<p className="sm:text-[20px] text-[15px] text-white text-opacity-50 font-thin">
						Organize and track your Recordings in one place.
					</p>
				</div>
				<div className="flex gap-4 flex-wrap">
					<div className="relative">
						<div className="absolute top-3 left-3">
							<Icon
								icon="lucide:search"
								width="2rem"
								height="2rem"
								style={{ color: "black" }}
							/>
						</div>
						<input
							type="text"
							placeholder="Search Recoding "
							className=" w-full sm:px-16 ps-14  pe-3 h-14 outline-none border-none text-black font-inter"
						/>
					</div>
					<button  onClick={() => setIsModalOpen(true)} className="h-14 px-6  mywids   gap-1 bg-[#3BE3E3] text-black font-inter sm:text-[18px] text-[14px] flex justify-center items-center">
						<Icon
							icon="f7:plus"
							width="1.6rem"
							height="1.6rem"
							style={{ color: "black" }}
						/>
						New Recordings
					</button>
				</div>
			</div>
      <div className="grid xl:grid-cols-2 grid-cols-1 mt-10  gap-0 text-white  border-[0.5px] border-white border-opacity-50">
				<div className=" py-10">
					<div className="sm:px-8 px-4 ">
						<Liftside data={data} />
            
					</div>
					<div className="h-[0.5px]  bg-opacity-50 my-10 w-full bg-white"></div>
					<div className="px-8">
						<div className="h-[300px]   relative z-10 rounded-[28px] overflow-hidden ">
							<img
								src={record2}
								className=" w-full h-full left-0 top-0 absolute"
								alt=""
							/>
							<img
								src={boxup}
								className=" w-1/2 h-full left-0 top-0 absolute"
								alt=""
							/>
						</div>
					</div>
				</div>

				{/* Right Side */}
				<div className=" sm:p-8 p-4 border-[0.5px] border-white border-opacity-50 ">
					<div className=" mt-2 w-full h-auto flex flex-col border-l-[0.5px overflow-hidden rounded-[30px]    sm:px-8 px-2 relative ">
						<img
							src={righboximg}
							alt=""
							className="absolute top-0 left-0  w-full h-full"
						/>
						<img
							src={boxup}
							alt=""
							className="absolute top-0 left-0  w-2/3 h-full"
						/>
						<div className="flex justify-between items-center  pt-10 pb-5 flex-wrap  ">
							<div className="relative rounded-full z-10">
								<img
									src={sound1}
									className="absolute left-0 top-0 w-full h-full -z-[9]"
									alt=""
								/>
								<h2 className="md:text-3xl sm:text-1xl text-[15px] font-light font-inter tracking-[0.1em]  sm:tracking-[0.2em] py-3 px-6">
									Recording List
								</h2>
							</div>
							<button className="ms-auto 	sm:mt-0 mt-4 border-2 border-black rounded-full px-6 py-2 z-20 ">
								Edit
							</button>
						</div>
						{/* Recordings List */}
						<Right data={data}/>
					</div>
				</div>
			</div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg w-[500px]">
            <h3 className="text-xl mb-4">Start Recording</h3>
            <input
              type="text"
              placeholder="Recording Name"
              value={recordingName}
              onChange={(e) => setRecordingName(e.target.value)}
              className="w-full mb-4 p-2 border"
            />
            {errors.recordingName && <p className="text-red-500 text-sm">{errors.recordingName}</p>}

            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full mb-4"
            />
            {errors.recordingImage && <p className="text-red-500 text-sm">{errors.recordingImage}</p>}
            {recordingImage && <img src={URL.createObjectURL(recordingImage)} alt="Recording" className="w-32 h-32 mb-4" />}

            <div className="flex justify-between items-center mb-4">
              <button
                onClick={handleStartRecording}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                Start Recording
              </button>
              <button
                onClick={() => setIsRecording(false)}
                className="px-4 py-2 bg-red-500 text-white rounded-lg"
                disabled={!isRecording}
              >
                Stop Recording
              </button>
            </div>

            <ReactMic
              record={isRecording}
              className="w-full"
              onStop={handleStopRecording}
              onData={() => {}}
            />

            {audioPreviewUrl && (
              <div className="mt-4">
                <audio controls src={audioPreviewUrl} />
              </div>
            )}

            {recordedAudio && (
              <div className="mt-4">
                <button onClick={handleSubmit} className="px-4 py-2 bg-green-500 text-white rounded-lg mr-2">
                  Submit
                </button>
                <button onClick={handleCancel} className="px-4 py-2 bg-gray-500 text-white rounded-lg">
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
