import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Protected({ Component }) {
	const navigate = useNavigate();

	useEffect(() => {
		const login = localStorage.getItem("login");
		if (!login) {
			navigate("/login");
		}
	}, [navigate]);

	return (
		<div>
			<Component />
		</div>
	);
}
